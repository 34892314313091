import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';

export const Container = styled.div`
    width: 100%;
    height: 100%;
`;

export const SidebarAdContainer = styled.div`
    margin-top: ${theme.space[24]};
    margin-bottom: ${theme.space[16]};
`;
