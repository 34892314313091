import { SITE_CONFIG } from '@config/siteConfig';
import { SellerAvatar } from '@domains/ad/nexusComponents/ContactForm/components/SellerAvatar/SellerAvatar';
import { CONTACT_FORM_DIALOG_ID } from '@domains/ad/nexusComponents/ContactForm/consts/contactFormDialogId';
import { ContactFormContext } from '@domains/ad/nexusComponents/ContactForm/contexts/ContactFormContext';
import { getSellerTypeLabel } from '@domains/ad/nexusComponents/ContactForm/helpers/getSellerTypeLabel';
import { PhoneNumber } from '@domains/ad/nexusComponents/PhoneNumber/PhoneNumber';
import type { Ad } from '@domains/ad/types/Ad';
import { usePhoneNumber } from '@domains/shared/hooks/usePhoneNumber/usePhoneNumber';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { useTracking } from '@lib/tracking/useTracking';
import { useDialogState } from '@nexus/lib-react/dist/core/Dialog';
import { H4, P3 } from '@nexus/lib-react/dist/core/Typography';
import { type JSX, useContext } from 'react';

import { Container, SellerInfoWrapper, SellerName, StyledButton } from './SellerInfoWithCta.theme';

const CONTACT_FORM_TRACKING_DATA = {
    touch_point_button: 'seller_info',
    contact_form_type: 'seller_info_contact_form',
};

export const SellerInfoWithCta = ({ ad }: { ad: Ad }): JSX.Element => {
    const [t] = useTranslations();
    const { country, countryCode: nationalCountryCode } = SITE_CONFIG.phoneNumbers;

    const { id, contactDetails } = ad;

    const { phoneNumber } = usePhoneNumber({
        number: contactDetails.phones?.[0] ?? '',
        nationalCountryCode,
        country,
    });

    const sellerType = t(getSellerTypeLabel(ad));
    const { setTrackingData } = useContext(ContactFormContext);
    const { trackEvent } = useTracking();
    const { openDialog } = useDialogState(CONTACT_FORM_DIALOG_ID);

    const handleContactClick = (): void => {
        setTrackingData(CONTACT_FORM_TRACKING_DATA);
        trackEvent('reply_message_form_click', CONTACT_FORM_TRACKING_DATA);
        openDialog();
    };

    return (
        <Container>
            <H4>{t('frontend.global.ad.contact-owner')}</H4>
            <SellerInfoWrapper>
                <SellerAvatar url={contactDetails.imageUrl} />
                <div>
                    <SellerName variant="bold">{contactDetails.name}</SellerName>
                    <P3>{sellerType}</P3>
                    {phoneNumber ? <PhoneNumber adId={id} phoneNumber={phoneNumber} /> : null}
                </div>
            </SellerInfoWrapper>
            <StyledButton size="small" onClick={handleContactClick}>
                {t('frontend.ad.contact-form.send-message')}
            </StyledButton>
        </Container>
    );
};
