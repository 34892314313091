import type { AdSlotId } from '@domains/shared/consts/adSlotsIds';
import { useEffect, useState } from 'react';

interface SlotRenderEndedEvent {
    isEmpty: boolean;
    slot: {
        getSlotElementId(): string;
    };
}

declare global {
    interface Window {
        googletag: {
            cmd: unknown[];
            pubads(): {
                addEventListener(eventType: string, handler: (event: SlotRenderEndedEvent) => void): void;
                removeEventListener(eventType: string, handler: (event: SlotRenderEndedEvent) => void): void;
            };
        };
    }
}

export const useBaxterAdsLoaded = (slotIds: AdSlotId[]): { didBaxterAdsLoad: boolean; adsLoaded: AdSlotId[] } => {
    const [adsLoaded, setAdsLoaded] = useState<AdSlotId[]>([]);
    const didBaxterAdsLoad = adsLoaded.length > 0;

    useEffect(() => {
        window.googletag = window.googletag || {};
        window.googletag.cmd = window.googletag.cmd || [];
        let eventHandler: (event: SlotRenderEndedEvent) => void;

        window.googletag.cmd.push(() => {
            if (window.googletag && window.googletag.pubads) {
                const pubadsService = window.googletag.pubads();
                eventHandler = (event: SlotRenderEndedEvent): void => {
                    const slotElementId = event.slot.getSlotElementId() as AdSlotId;

                    if (!event.isEmpty && slotIds.includes(slotElementId)) {
                        setAdsLoaded((previous) => {
                            if (!previous.includes(slotElementId)) {
                                return [...previous, slotElementId];
                            }

                            return previous;
                        });
                    }
                };

                pubadsService.addEventListener('slotRenderEnded', eventHandler);
            }
        });

        return (): void => {
            if (window.googletag && window.googletag.pubads && eventHandler) {
                const pubadsService = window.googletag.pubads();
                pubadsService.removeEventListener('slotRenderEnded', eventHandler);
            }
        };
    }, [slotIds]);

    return { didBaxterAdsLoad, adsLoaded };
};
