import type { DFPConfig } from '@config/type';
import { ContactForm } from '@domains/ad/nexusComponents/ContactForm/ContactForm';
import type { Ad } from '@domains/ad/types/Ad';
import { AdvertSlot } from '@domains/shared/components/Advertising/AdvertSlot';
import { useBaxterSlots } from '@domains/shared/components/Advertising/getAdverts';
import { useBaxterAdvertising } from '@domains/shared/components/Advertising/useBaxterAdvertising';
import { AdUnit as AdvertisementUnit } from '@domains/shared/components/dataDisplay/AdUnit/AdUnit';
import type { AdSlotId } from '@domains/shared/consts/adSlotsIds';
import { useBaxterAdsLoaded } from '@domains/shared/hooks/useBaxterAdsLoaded/useBaxterAdsLoaded';
import { type JSX, type MutableRefObject } from 'react';

import { Container, SidebarAdContainer } from './AdSidebar.theme';
import { SellerInfoWithCta } from './components/SellerInfoWithCTA/SellerInfoWithCta';

interface Props {
    ad: Ad;
    dfp: DFPConfig;
    sidebarAdContainer: MutableRefObject<HTMLDivElement | null>;
    isMobile: boolean;
}

interface AdUnitProps {
    dataCy: string;
    slotId: string;
    adUnit?: string;
    sizes: [number, number][];
    slotWidth: string;
}

const BAXTER_CONTAINERS: AdSlotId[] = [
    'baxter-a-right-first-inner',
    'baxter-a-right-second-inner',
    'baxter-a-right-third-inner',
];

export const AdSidebar = ({ ad, dfp, sidebarAdContainer, isMobile }: Props): JSX.Element => {
    useBaxterAdvertising();
    const shouldRenderBaxterAds = !isMobile;
    const shouldRenderAdUnit = isMobile;

    const { didBaxterAdsLoad } = useBaxterAdsLoaded(BAXTER_CONTAINERS);

    const didBaxterAdsNotLoaded = !didBaxterAdsLoad && !isMobile;

    useBaxterSlots({ target: 'adPage', params: ad.target });

    return (
        <Container>
            <ContactForm ad={ad} />

            <SidebarAdContainer ref={sidebarAdContainer}>
                {shouldRenderBaxterAds ? (
                    <AdvertSlotsBlock slots={[{ id: 'baxter-a-right-first' }, { id: 'baxter-a-right-second' }]} />
                ) : null}
                {shouldRenderAdUnit ? (
                    <AdvertisementUnitsBlock
                        units={[
                            {
                                dataCy: 'adPageSidebarAdUnit1',
                                slotId: 'adPageSidebarAdUnit1',
                                adUnit: dfp.type.adPage.halfpage,
                                sizes: [
                                    [300, 250],
                                    [300, 600],
                                ],
                                slotWidth: '300px',
                            },
                            {
                                dataCy: 'adPageSidebarAdUnit2',
                                slotId: 'adPageSidebarAdUnit2',
                                adUnit: dfp.type.adPage.native,
                                sizes: [[300, 250]],
                                slotWidth: '300px',
                            },
                        ]}
                    />
                ) : null}
                {shouldRenderBaxterAds ? <AdvertSlotsBlock slots={[{ id: 'baxter-a-right-third' }]} /> : null}
                {shouldRenderAdUnit ? (
                    <AdvertisementUnitsBlock
                        units={[
                            {
                                dataCy: 'adPageSidebarAdUnit3',
                                slotId: 'adPageSidebarAdUnit3',
                                adUnit: dfp.type.adPage.calculator,
                                sizes: [
                                    [300, 250],
                                    [300, 600],
                                    [300, 300],
                                ],
                                slotWidth: '300px',
                            },
                            {
                                dataCy: 'adPageSidebarAdUnit4',
                                slotId: 'adPageSidebarAdUnit4',
                                adUnit: dfp.type.adPage.halfpageSec,
                                sizes: [
                                    [300, 250],
                                    [300, 600],
                                ],
                                slotWidth: '300px',
                            },
                        ]}
                    />
                ) : null}
            </SidebarAdContainer>

            {didBaxterAdsNotLoaded ? null : <SellerInfoWithCta ad={ad} />}
        </Container>
    );
};

const AdvertisementUnitsBlock = ({ units }: { units: AdUnitProps[] }): JSX.Element => (
    <>
        {units.map((unit) => (
            <AdvertisementUnit
                key={unit.slotId}
                data-cy={unit.dataCy}
                slotId={unit.slotId}
                adUnit={unit.adUnit}
                sizes={unit.sizes}
                slotWidth={unit.slotWidth}
            />
        ))}
    </>
);

const AdvertSlotsBlock = ({ slots }: { slots: { id: string }[] }): JSX.Element => (
    <>
        {slots.map(({ id }) => (
            <AdvertSlot id={id} key={id} />
        ))}
    </>
);

export default AdSidebar;
