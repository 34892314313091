import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';
import { Button } from '@nexus/lib-react/dist/core/Button';
import { P2 } from '@nexus/lib-react/dist/core/Typography';

export const Container = styled.div`
    position: sticky;
    top: ${theme.space['16']};
    padding: ${theme.space['24']};
    background-color: ${theme.colors.$01_white};
`;

export const SellerInfoWrapper = styled.div`
    display: flex;
    margin-top: ${theme.space['16']};
    gap: ${theme.space['16']};
`;

export const SellerName = styled(P2)`
    margin-bottom: ${theme.space['4']};
`;

export const StyledButton = styled(Button)`
    width: 100%;
    margin-top: ${theme.space['24']};
`;
